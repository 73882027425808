<template>
  <b-row class="text-nowrap">
    <b-col
      class="mt-1 mr-1"
      md="auto"
    >
      <item-statistics
        color="light-primary"
        :value="statisticsData.total"
        title="Toplam"
        icon="InfoIcon"
      />
    </b-col>
    <b-col
      class="mt-1 mr-1"
      md="auto"
    >
      <item-statistics
        color="light-success"
        :value="statisticsData.completed"
        title="Tamamlanan"
        icon="CheckCircleIcon"
      />
    </b-col>
    <b-col
      class="mt-1 mr-1"
      md="auto"
    >
      <item-statistics
        color="light-danger"
        :value="statisticsData.high_low"
        title="0-3 Puan"
        icon="XCircleIcon"
      />
    </b-col>
    <b-col
      class="mt-1 mr-1"
      md="auto"
    >
      <item-statistics
        color="light-warning"
        :value="statisticsData.low"
        title="3-5 Puan"
        icon="InfoIcon"
      />
    </b-col>
    <b-col
      class="mt-1 mr-1"
      md="auto"
    >
      <item-statistics
        color="light-info"
        :value="Number(statisticsData.natural)"
        title="5-7 Puan"
        icon="CheckSquareIcon"
      />
    </b-col>
    <b-col
      class="mt-1 mr-1"
      md="auto"
    >
      <item-statistics
        color="light-success"
        :value="Number(statisticsData.good)"
        title="8-9 Puan"
        icon="CheckSquareIcon"
      />
    </b-col>
    <b-col
      class="mt-1 mr-1"
      md="auto"
    >
      <item-statistics
        color="light-success"
        :value="Number(statisticsData.very_good)"
        title="9+ Puan"
        icon="CheckSquareIcon"
      />
    </b-col>
    <b-col
      class="mt-1 mr-1"
      md="auto"
    >
      <item-statistics
        color="light-info"
        :value="Number(statisticsData.avarage)"
        title="Ortalama Puan"
        icon="BarChartIcon"
      />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import ItemStatistics from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/ItemStatistics.vue'

export default {
  name: 'SurveysReportIstatisticsCard',
  components: {
    BRow,
    BCol,
    ItemStatistics,
  },
  props: {
    statisticsData: {
      type: Object,
      required: true,
    },
  },
}
</script>
