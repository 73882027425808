<template>
  <div>
    <b-row class="match-height d-flex align-items-center">
      <b-col>
        <b-input-group>
          <v-select
            id="id_com_survey_type"
            v-model="filterDetail.id_com_survey_type"
            label="title"
            :reduce="type => type.id"
            :options="types"
            placeholder="Anket Tipi"
            class="w-100"
            @input="filterAction"
          />
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="detailFilter"
          switch
          inline
          @click="detailFilter = !detailFilter"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="detailFilter">
      <b-col class="mt-1">
        <survey-reports-dates-card />
      </b-col>
      <b-col class="mt-1">
        <survey-report-status-card />
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          :disabled="dataLoading"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          variant="warning"
          class="ml-1"
          @click="resetFilter"
        >
          <FeatherIcon icon="TrashIcon" />
          Filtreyi Sıfırla
        </b-button>
        <b-button
          variant="danger"
          class="ml-1"
          :href="downloadExcel"
          target="_blank"
          :disabled="reportData.length < 1"
        >
          <FeatherIcon icon="DownloadIcon" />
          Excel Aktar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SurveyReportsDatesCard from '@/views/Reports/Crm/SurveysReport/Elements/SearchBarDatesCard.vue'
import SurveyReportStatusCard from '@/views/Reports/Crm/SurveysReport/Elements/SurveyStatusCard.vue'

export default {
  name: 'SurveysReportSearchComponent',
  components: {
    SurveyReportStatusCard,
    SurveyReportsDatesCard,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BButton,
    vSelect,
  },
  props: {
    filterAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      detailFilter: true,
    }
  },
  computed: {
    filterDetail() {
      return this.$store.getters['surveysReport/getFilterData']
    },
    dataLoading() {
      return this.$store.getters['surveysReport/getDataLoading']
    },
    reportData() {
      return this.$store.getters['surveysReport/getDataList']
    },
    types() {
      return this.$store.getters['surveyType/getDataList']
    },
    downloadExcel() {
      const urlParams = []
      if (this.filterDetail.sdate) {
        urlParams.push(`sdate=${this.filterDetail.sdate}`)
      }
      if (this.filterDetail.edate) {
        urlParams.push(`edate=${this.filterDetail.edate}`)
      }
      if (this.filterDetail.id_com_survey_type) {
        urlParams.push(`id_com_survey_type=${this.filterDetail.id_com_survey_type}`)
      }
      if (this.filterDetail.id_com_survey_status) {
        urlParams.push(`id_com_survey_status=${this.filterDetail.id_com_survey_status}`)
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/reports/crm/surveys/surveysExport?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  created() {
    this.surveyTypes()
  },
  methods: {
    resetFilter() {
      this.$store.dispatch('surveysReport/resetFilterData')
    },
    surveyTypes() {
      this.$store.dispatch('surveyType/dataList', {
        select: [
          'com_survey_type.id AS id',
          'com_survey_type.title AS title',
        ],
      })
    },
  },
}
</script>
